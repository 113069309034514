// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@use "@angular/material" as mat;
@import "@angular/cdk/overlay-prebuilt.css";
@import "https://rsms.me/inter/inter.css";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
$custom-app-primary: mat.define-palette(mat.$indigo-palette);
$custom-app-accent: mat.define-palette(mat.$pink-palette);
$custom-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-app-primary,
      accent: $custom-app-accent,
    ),
  )
);
@include mat.legacy-dialog-theme($custom-app-theme);
@include mat.legacy-tabs-theme($custom-app-theme);

/* You can add global styles to this file, and also import other style files */
.cdk-drag-preview .drag-outline,
.cdk-drag-preview.drag-outline {
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-preview .drag-hide {
  display: none;
}

.cdk-drag-placeholder {
  opacity: 0.2;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// For tooltip

[data-tooltip]:not([data-tooltip=""]) {
  position: relative;

  &:before {
    content: attr(data-tooltip); /* here's the magic */
    position: absolute;

    /* vertically center */
    top: 80%;
    transform: translateY(-50%);

    /* move to right */
    left: 80%;

    /* basic styles */
    padding: 5px;
    font-size: 0.75em;
    border-radius: 5px;
    background: #d8d8d8;
    color: #333333;
    width: attr(data-tooltip-width);

    text-align: center;

    // display: none;

    z-index: 50;

    // For Animation
    opacity: 0;
    transition: 0.3s opacity;

    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  &:hover:before {
    // display: block;
    opacity: 1;
    animation: fadeOut 1s forwards;
    animation-delay: 3s;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

// Horizontal scrollbar styling

.overflow-x-scroll::-webkit-scrollbar {
  height: 10px;
}

.overflow-x-scroll::-webkit-scrollbar-track {
  background-color: rgb(239, 221, 243);
  border-radius: 10px;
}

.overflow-x-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(103, 7, 182, 0.4);
  // box-shadow: inset 0 0 6px rgba(103, 7, 182, 0.4);
}

// For firefox
.overflow-x-scroll {
  scrollbar-color: rgba(103, 7, 182, 0.4) rgb(239, 221, 243);
  scrollbar-width: thin;
}
.scroll::-webkit-scrollbar {
  height: 10px;
}

.scroll::-webkit-scrollbar-track {
  background-color: rgb(239, 221, 243);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(103, 7, 182, 0.4);
  // box-shadow: inset 0 0 6px rgba(103, 7, 182, 0.4);
}
// For firefox
.scroll {
  scrollbar-color: rgba(103, 7, 182, 0.4) rgb(239, 221, 243);
  scrollbar-width: thin;
}
// Vertical scrollbar styling

.overflow-y-auto::-webkit-scrollbar {
  width: 10px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background-color: rgb(239, 221, 243);
  border-radius: 10px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(103, 7, 182, 0.4);
  // box-shadow: inset 0 0 6px rgba(103, 7, 182, 0.4);
}

// For firefox
.overflow-y-auto {
  scrollbar-color: rgba(103, 7, 182, 0.4) rgb(239, 221, 243);
  scrollbar-width: thin;
}

html,
body {
  font-family: "Inter" !important;
  font-feature-settings: "salt", "zero" !important;
  color: #171717;
}

// For Simplemde
.CodeMirror,
.CodeMirror-scroll {
  max-height: 80vh;
}

.CodeMirror-fullscreen.CodeMirror {
  max-height: none;
}

.CodeMirror-fullscreen .CodeMirror-scroll {
  max-height: none;
}

// Printing

body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

@media print {
  body {
    visibility: hidden;
  }

  .for-printing-exclusive {
    visibility: visible !important;
    display: block !important;
    background: white;
    position: absolute;
    padding: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

// Backwards compatibility Material Dialog
.mat-mdc-dialog-surface {
  padding: 24px;
}
